import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ISitemapCategory } from './models';
import SitemapCategoryItem from './SitemapCategoryItem/SitemapCategoryItem';

import './SitemapCategory.scss';

const SitemapCategory: FC<ISitemapCategory> = ({ name, link, subLinks }) => (
  <div className="dt-sitemap-category">
    <strong className="dt-sitemap-category__subtitle">
      <Link to={link}>{name}</Link>
    </strong>

    {subLinks?.length ? (
      <ul className="dt-sitemap-category__list" key={subLinks.length}>
        {subLinks.map((item) => (
          <SitemapCategoryItem key={item.id} {...item} />
        ))}
      </ul>
    ) : null}
  </div>
);

export default SitemapCategory;

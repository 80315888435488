import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ISitemapCategoryItem } from './models';

const SitemapCategoryItem: FC<ISitemapCategoryItem> = ({ name, link, subLinks }) => (
  <li className="dt-sitemap-category__item">
    <Link to={link}>{name}</Link>
    {subLinks ? (
      <ul className="dt-sitemap-category__list">
        {subLinks.map((item) => (
          <SitemapCategoryItem {...item} />
        ))}
      </ul>
    ) : null}
  </li>
);

export default SitemapCategoryItem;

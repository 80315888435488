import React from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import SitemapCategory from 'components/SitemapCategory';

import 'styles/main.scss';
import './SitemapPage.scss';
import './SitemapPageOverride.scss';

const SitemapPage = ({
  data: {
    articleListing: { nodes: articleListingPages },
    articleDetail: { nodes: articleDetailPages },
    productListing: { nodes: productListingPages },
    productDetail: { nodes: productDetailPages },
    allLds: { nodes: ldsPages },
    purposePage,
    newsletterSignUp,
    faq,
    contactUs,
    tips,
    sitemap: {
      title: sitemapTitle,
      seoMetaDescription,
      seoNoIndex,
      seoMetaTitle,
      seoMetaKeywords,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
      link,
    },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const langCode = (siteSettings?.siteLang || siteSettings?.lang)?.substring(0, 2);
  const mappedArticles = articleListingPages
    .filter((page) => page.link.split('/').filter((linkPart) => linkPart !== langCode)[2] === '')
    .map((page) => ({
      ...page,
      name: `${page.titleRegular} ${page.titleBold}`,
      subLinks: articleListingPages
        .filter((listing) => listing.link.indexOf(page.link) > -1 && listing.link !== page.link)
        .map((listing) => ({
          ...listing,
          name: listing.name,
          subLinks: articleDetailPages
            .filter((detailPage) => detailPage.link.indexOf(listing.link) > -1)
            .map((detailPage) => ({
              ...detailPage,
              name: detailPage.title,
            })),
        })),
    }));

  const mappedProducts = productListingPages
    .filter((page) => page.link.split('/').filter((linkPart) => linkPart !== langCode)[2] === '')
    .map((page) => ({
      ...page,
      name: `${page.titleRegular} ${page.titleBold}`,
      subLinks: productDetailPages
        .filter((detailPage) => detailPage.link.indexOf(page.link) > -1)
        .map((detailPage) => ({ ...detailPage })),
    }));

  const mappedLds = ldsPages.map((page) => ({
    name: page.title,
    link: page.link,
  }));

  const mappedPurpose = {
    name: purposePage.title,
    link: purposePage.link,
    subLinks: articleDetailPages
      .filter((detailPage) => detailPage.link.indexOf(purposePage.link) > -1)
      .map((detailPage) => ({
        ...detailPage,
        name: detailPage.title,
      })),
  };

  const categories = [
    {
      name: faq?.title,
      link: faq?.link,
    },
    {
      name: contactUs?.title,
      link: contactUs?.link,
    },
    {
      name: tips?.name,
      link: tips?.link,
    },
  ];

  if (newsletterSignUp) {
    categories.unshift({
      name: `${newsletterSignUp.signUpBanner[0].regularTitle} ${newsletterSignUp.signUpBanner[0].boldTitle}`,
      link: newsletterSignUp.link,
    });
  }

  categories.push(...mappedLds, mappedPurpose, ...mappedArticles, ...mappedProducts);

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaDescription,
        seoMetaTitle,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <Container fluid className="dt-sitemap">
        <h1 className="dt-sitemap__title">{sitemapTitle}</h1>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row>
              {categories?.map((category) =>
                category?.link && category?.name ? (
                  <Col md={6}>
                    <SitemapCategory {...category} />
                  </Col>
                ) : null
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SitemapPage;

export const query = graphql`
  query SitemapPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    articleListing: allUmbracoArticleListingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
        titleBold
        titleRegular
      }
    }
    articleDetail: allUmbracoArticle(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        title
        link
      }
    }
    productListing: allUmbracoProductListingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
        titleBold
        titleRegular
      }
    }
    productDetail: allUmbracoProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
      }
    }
    purposePage: umbracoPurpose(lang: { eq: $lang }) {
      title
      link
    }
    faq: umbracoFaq(lang: { eq: $lang }) {
      title
      link
    }
    contactUs(lang: { eq: $lang }) {
      link
      title
    }
    tips: umbracoTips(lang: { eq: $lang }) {
      link
      name
    }
    sitemap(lang: { eq: $lang }) {
      link
      title
      seoNoIndex
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
    }
    allLds(filter: { lang: { eq: $lang } }) {
      nodes {
        title
        link
      }
    }
  }
`;
